import * as React from 'react';

import {
    AppBar,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    AccountCircle,
    Close
} from '@mui/icons-material';
import memoize from 'memoize-one';
import { VERSION } from '../../constants';
import { ccLogoNoText } from '../../shared/assets/images/cc-logo';
import { UserTeam } from '../../shared/domain';
import {
    WithMediaQuery,
    withMediaQuery
} from '../../shared/components/with-media-query';
import { RESPONSIVE_BREAKPOINTS } from '../../shared/utils/mui-utils'; 


const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    rightMenuWrapper: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.between('xs', theme.breakpoints.values.sm)]: {
            minWidth: 0,
            marginLeft: '1em'
        }
    },
    userName: {
        wordBreak: 'break-all'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '3em',
        [theme.breakpoints.only('xs')]: {
            padding: '0 0 0 0.8em'
        }
    },
    bottomDivider: {
        marginTop: '1em'
    },
    dropdownButton: {
        '&$dropdownButtonDisabled': {
            color: 'rgba(255,255,255,0.5)'
        }
    },
    dropdownButtonDisabled: { }, // Used only as reference
    ccLogo: {
        height: '1.45em'
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        padding: '0.3em 0.3em 0 0',
    },
    version: {
        textAlign: 'end',
        fontSize: '0.75em',
        fontWeight: 900
    },
    menuRootContainer: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.sm)]: {
            top: '0!important', // Forced to use 'important' because these two properties
            left: '0!important', // are injected in the style property of the DOM element
            minWidth: '100%',
            borderRadius: 0
        }
    },
    menuRoot: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0
    },
    menuItem: {
        padding: '0.6em 1em'
    },
    menuItemTitle: {
        alignSelf: 'center',
        fontSize: '1.3em',
        fontWeight: 'bold',
        padding: '0.5em'
    },
    menuItemTeamsTitle: {
        paddingBottom: 0
    },
    menuItemTeam: {
        padding: '0.5em 1em 0 2em',
        whiteSpace: 'normal',
        '&$menuItemTeamSelected': {
            fontWeight: 'bold',
            fontStyle: 'italic'
        }
    },
    menuItemTeamSelected: {}
});

interface Props extends WithMediaQuery<WithStyles<typeof styles>> {
    userName: string;
    teams: UserTeam[];
    teamSelectedId?: string;
    onLogout?: () => void;
    onTeamClick?: (team: UserTeam) => void;
}

interface States {
    isMenuOpen: boolean;
}

class CCToolbar extends React.Component<Props, States> {
    public static defaultProps = {
    };

    state = {
        isMenuOpen: false
    };

    menuAnchorElement: HTMLElement | null = null;

    generateMenuItems = memoize((teams: UserTeam[], teamSelectedId: string | undefined) : React.ReactNode[] => {
        const {
            classes
        } = this.props;
        return teams.map((team: UserTeam, index) => {
            const {
                teamId,
                name
            } = team;
            const isTeamSelected =
                teamSelectedId ? team.teamId === teamSelectedId : false;

            return (
                <MenuItem
                    key={`${index}-${teamId}`}
                    className={`${classes.menuItemTeam}${isTeamSelected ? ` ${classes.menuItemTeamSelected}` : ''}`}
                    onClick={this.onTeamClicked(team)}
                    disabled={isTeamSelected}
                >
                    {name}
                </MenuItem>
            );
        });
    });

    closeMenu = () => {
        this.setState({isMenuOpen: false});
    };

    onProfileIconClicked = (event:React.MouseEvent<HTMLElement>) => {
        if (!this.menuAnchorElement) {
            this.menuAnchorElement = event.currentTarget;
        }
        this.setState({isMenuOpen: true});
    };

    onLogoutClicked = () => {
        const {
            onLogout
        } = this.props;

        if (onLogout) {
            onLogout();
        }

        this.closeMenu();
    };

    onTeamClicked = (team: UserTeam) => () => {
        const {
            onTeamClick
        } = this.props;

        if (onTeamClick) {
            onTeamClick(team);
        }

        this.closeMenu();
    };

    public render() {
        const {
            classes,
            userName,
            teamSelectedId,
            teams,
            mediaQuery
        } = this.props;
        const {
            isMenuOpen
        } = this.state;

        return (
            <div className={classes.root}>
                <AppBar
                    position="static"
                    color="secondary"
                >
                    <Toolbar
                        className={classes.toolbar}
                    >
                        <div>
                            <img
                                className={classes.ccLogo}
                                alt="CrowdComfort Logo"
                                src={ccLogoNoText}
                            />
                            <Typography
                                className={classes.version}
                                variant="subtitle2"
                                noWrap={true}
                            >
                                V{VERSION}
                            </Typography>
                        </div>
                        <div
                            className={classes.rightMenuWrapper}
                        >
                            { userName ?
                                <Typography
                                    className={classes.userName}
                                    variant="subtitle2"
                                    color="inherit"
                                    noWrap={true}
                                >
                                    {userName}
                                </Typography>
                                : ''
                            }
                            <IconButton
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.onProfileIconClicked}
                                color="inherit"
                                classes={{
                                    root: classes.dropdownButton,
                                    disabled: classes.dropdownButtonDisabled
                                }}
                            >
                                <AccountCircle />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Menu
                    anchorEl={this.menuAnchorElement}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{
                        paper: classes.menuRootContainer
                    }}
                    MenuListProps={{
                        classes: {
                            root: classes.menuRoot
                        }
                    }}
                    open={isMenuOpen}
                    onClose={this.closeMenu}
                >
                    { mediaQuery ? 
                        <IconButton
                            className={classes.closeButton}
                            onClick={this.closeMenu}
                        >
                            <Close />
                        </IconButton>
                        : ''
                    }
                    <MenuItem
                        className={classes.menuItemTitle}
                        disabled={true}
                    >
                        User Actions
                    </MenuItem>
                    <Divider />
                    { (teams.length > 0) ? 
                        <MenuItem
                            className={classes.menuItemTeamsTitle}
                            disabled={true}
                        >
                            Teams
                        </MenuItem>
                        : ''
                    }
                    {
                        this.generateMenuItems(teams, teamSelectedId)
                    }
                    { (teams.length > 0) ? 
                        <Divider className={classes.bottomDivider} />
                        : ''
                    }
                    <MenuItem
                        className={classes.menuItem}
                        onClick={this.onLogoutClicked}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(withMediaQuery(`(max-width:${RESPONSIVE_BREAKPOINTS.sm}px)`)(CCToolbar));
export {MUIComponent as CCToolbar};
