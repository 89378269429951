export * from './shared/constants';

const {
    REACT_APP_LOCATION_FILTER_BUFFER_LIMIT,
    REACT_APP_MARKERS_MAX_ROWS,
    REACT_APP_MARKERS_BUFFER_LIMIT,
    REACT_APP_TEAM_REPORTS_BUFFER_LIMIT,
    REACT_APP_TEAM_REPORTS_MAX_ROWS,
    REACT_APP_VERSION,
    REACT_APP_IOS_APP_STORE_LINK,
    REACT_APP_ANDROID_GOOGLE_STORE_LINK
} = process.env;

const TEAM_REPORTS_MAX_ROWS =
    REACT_APP_TEAM_REPORTS_MAX_ROWS ?
        Number(REACT_APP_TEAM_REPORTS_MAX_ROWS) : 0;
const TEAM_REPORTS_BUFFER_LIMIT =
    REACT_APP_TEAM_REPORTS_BUFFER_LIMIT ?
        Number(REACT_APP_TEAM_REPORTS_BUFFER_LIMIT) : 0;
const TEAM_REPORTS_RECORD_LIMIT = 
    Math.ceil(TEAM_REPORTS_BUFFER_LIMIT/TEAM_REPORTS_MAX_ROWS)
        * TEAM_REPORTS_MAX_ROWS;

const MARKERS_MAX_ROWS =
    REACT_APP_MARKERS_MAX_ROWS ?
        Number(REACT_APP_MARKERS_MAX_ROWS) : 0;
const MARKERS_BUFFER_LIMIT =
    REACT_APP_MARKERS_BUFFER_LIMIT ?
        Number(REACT_APP_MARKERS_BUFFER_LIMIT) : 0;
const MARKERS_RECORD_LIMIT =
    Math.ceil(MARKERS_BUFFER_LIMIT / MARKERS_MAX_ROWS)
        * MARKERS_MAX_ROWS;

const LOCATION_FILTER_BUFFER_LIMIT =
    REACT_APP_LOCATION_FILTER_BUFFER_LIMIT ?
        Number(REACT_APP_LOCATION_FILTER_BUFFER_LIMIT) : 0;

export const VERSION = REACT_APP_VERSION;
export const CC_APP_TYPE = 7;
export const COOKIE_NAMES = {
    LOGIN_AUTHORIZATION: 'la',
    REGISTERED_USER: 'ru',
    USER_VERIFICATION_PASSWORD: 'uvp',
    REPORT_FILTERS: 'rf',
    QR_CODE: 'qc',
    CURRENT_TEAM: 'ct',
    REPORT_DRAWER_OPEN: 'rdo'
};
export const REPORTS = {
    TEAM_REPORTS_MAX_ROWS,
    TEAM_REPORTS_RECORD_LIMIT,
    MARKERS_MAX_ROWS,
    MARKERS_RECORD_LIMIT,
    LOCATION_FILTER_BUFFER_LIMIT
};
export const MOBILE = {
    IOS: {
        STORE_LINK: REACT_APP_IOS_APP_STORE_LINK
    },
    ANDROID: {
        STORE_LINK: REACT_APP_ANDROID_GOOGLE_STORE_LINK,
    }
};
