// tslint: no-empty
import { RouterState, RouterStore } from 'mobx-state-router';
import { AnalyticsAdapter } from '../adapters/analytics-adapter';
import { LoginAuthorizationAdapter } from '../adapters/login-authorization-adapter';
import { ReportAdapter } from '../adapters/report-adapter';
import { ManagerStore } from './manager.store';
import { RootStoreBase } from './root.store.base';
import { ROUTE_NAMES, routes } from './routes';
import { StoreAdapters } from './store-adapters';

const notFound = new RouterState(ROUTE_NAMES.NOT_FOUND);

export class RootStore implements RootStoreBase {
    routerStore: RouterStore = new RouterStore(this, routes, notFound);
    managerStore: ManagerStore = new ManagerStore(this);

    // Adapters for use by all stores
    adapters: StoreAdapters  = {
        analyticsAdapter: AnalyticsAdapter.getAdapter(),
        loginAuthorizationAdapter: LoginAuthorizationAdapter.getAdapter(),
        reportAdapter: ReportAdapter.getAdapter() as ReportAdapter
    };

    // ----- Life cycle hooks -----
    // Useful for starting and stopping observers, autoruns and reactions

    init(): void { return; }

    destroy(): void { return; }
}
