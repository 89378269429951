const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY as string;
const {
    REACT_APP_BASE_API_URL: BASE_API_URL,
    REACT_APP_BASE_API_V1_URL: BASE_API_V1_URL,
    REACT_APP_BASE_IMAGE_API_URL: BASE_IMAGE_API_URL,
    REACT_APP_EXPORT_TEAM_REPORTS_URL: TEAM_REPORTS_EXPORT,
    REACT_APP_EXPORT_TEAM_REPORTS_CAP,
    REACT_APP_EXPORT_TEAM_REPORTS_LIMIT,
    REACT_APP_TABLEAU_BASE_URL: TABLEAU_BASE_URL,
    REACT_APP_TABLEAU_BASE_API_URL: TABLEAU_BASE_API_URL,
    REACT_APP_TABLEAU_REFRESH_RATE,
    REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_RELEASE_VERSION
} = process.env;
const CAPTCHA_VERIFY_URL = BASE_API_URL+'recaptcha_verify';
const TABLEAU_REFRESH_RATE = REACT_APP_TABLEAU_REFRESH_RATE ? Number(REACT_APP_TABLEAU_REFRESH_RATE) : 60;
const EXPORT_TEAM_REPORTS_CAP = REACT_APP_EXPORT_TEAM_REPORTS_CAP ? Number(REACT_APP_EXPORT_TEAM_REPORTS_CAP) : 0;
const EXPORT_TEAM_REPORTS_LIMIT = REACT_APP_EXPORT_TEAM_REPORTS_LIMIT ? Number(REACT_APP_EXPORT_TEAM_REPORTS_LIMIT) : 0;
const ACCOUNT_BASE = BASE_API_URL + 'accounts';
const FOLDER_BASE =  BASE_API_URL + 'folders';
const FOLDER_TOKEN = '[folder_id]';
const MARKER_TOKEN = '[marker_id]';
const LIMIT_TOKEN = '[limit]';
const OFFSET_TOKEN = '[offset]';
const ACCOUNT_TOKEN = '[account_id]';
const ACCOUNT_TEAM_TOKEN = '[team]';
const ACCOUNT_TEAM_ID_TOKEN = '[team_id]';
const ACCOUNT_TEAM_USER_ID_TOKEN = '[user_id]';
const USERS_ADD_TEAM_USER_ID = '[user_id]';
const USERS_ADD_TEAM_TEAM_ID = '[team_id]';
const TEAM_REPORTS_TEAM_ID = USERS_ADD_TEAM_TEAM_ID;
const TEAM_REPORTS_SEARCH = '[team_id_search]';
const REPORTS_REPORT_ID = '[report_id]';
const REPORTS_USER_ID = '[user_id]';
const USERS_USER_ID = '[user_id]';
const ANALYTICS_TAB_ID = '[tab_id]';
const REPORTS_FOLDER_ID = '[folder_id]';
const REPORTS_FLOORPLAN_ID = '[floorplan_id]';

export const SENTRY_DSN = REACT_APP_SENTRY_DSN;
export const SENTRY_RELEASE_VERSION = REACT_APP_SENTRY_RELEASE_VERSION;

export {
    CAPTCHA_SITE_KEY,
    EXPORT_TEAM_REPORTS_CAP,
    EXPORT_TEAM_REPORTS_LIMIT
};
export const API = {
    URL: {
        Tokens: {
            ACCOUNTS: {
                ACCOUNT: ACCOUNT_TOKEN,
                FOLDER: FOLDER_TOKEN,
                LIMIT: LIMIT_TOKEN,
                OFFSET: OFFSET_TOKEN
            },
            ACCOUNTS_TEAMS: {
                ACCOUNT: ACCOUNT_TEAM_TOKEN,
                TEAM_ID: ACCOUNT_TEAM_ID_TOKEN,
                USER_ID: ACCOUNT_TEAM_USER_ID_TOKEN,
                LIMIT: LIMIT_TOKEN,
                OFFSET: OFFSET_TOKEN
            },
            ANALYTICS: {
                TAB_ID: ANALYTICS_TAB_ID
            },
            FOLDERS: {
                FOLDER: FOLDER_TOKEN
            },
            MARKERS: {
                MARKER_ID: MARKER_TOKEN
            },
            AUTHENTICATION: {
                AUTHORIZATION_CODE: '<AUTHORIZATION_CODE>'
            },
            REPORTS: {
                TEAM_ID: TEAM_REPORTS_TEAM_ID,
                SEARCH: TEAM_REPORTS_SEARCH,
                REPORT_ID: REPORTS_REPORT_ID,
                USER_ID: REPORTS_USER_ID,
                FOLDER_ID: REPORTS_FOLDER_ID,
                FLOORPLAN_ID: REPORTS_FLOORPLAN_ID
            },
            USERS: {
                USER_ID: USERS_USER_ID,
                ADD_TEAM: {
                    USER_ID: USERS_ADD_TEAM_USER_ID,
                    TEAM_ID: USERS_ADD_TEAM_TEAM_ID
                }
            }
        },
        BASE_API_URL,
        ACCOUNTS: {
            ACCOUNTS: ACCOUNT_BASE + `?limit=${LIMIT_TOKEN}&offset=${OFFSET_TOKEN}`,
            ACCOUNT: ACCOUNT_BASE + `/${ACCOUNT_TOKEN}/datum`,
            TEAMS: ACCOUNT_BASE + `/${ACCOUNT_TEAM_TOKEN}/teams?limit=${LIMIT_TOKEN}&offset=${OFFSET_TOKEN}`,
            TEAM_BY_USER: ACCOUNT_BASE + `/team/${ACCOUNT_TEAM_ID_TOKEN}/user/${ACCOUNT_TEAM_USER_ID_TOKEN}`,
            INSPECTIONS: {
                BUILDING_FOLDERS: ACCOUNT_BASE+`/${ACCOUNT_TOKEN}/inspections/building_folders`,
                STATUS_SUMMARIES: ACCOUNT_BASE+`/${ACCOUNT_TOKEN}/inspections/building_folders/${FOLDER_TOKEN}/status_summaries_by_asset`,
                SUMMARIES: ACCOUNT_BASE+`/${ACCOUNT_TOKEN}/inspections/summaries`,
                SUBMISSIONS: ACCOUNT_BASE+`/${ACCOUNT_TOKEN}/inspections/submissions`
            }
        },
        FOLDERS: FOLDER_BASE+`/${FOLDER_TOKEN}`,
        USERS: {
            USERS: BASE_API_URL + `users/${USERS_USER_ID}`,
            USERS_BY_EMAIL: BASE_API_URL + 'users/email/',
            ADD_REMOVE_TEAM: BASE_API_URL + `users/${USERS_ADD_TEAM_USER_ID}/teams/${USERS_ADD_TEAM_TEAM_ID}`    
        },
        TEAMS: BASE_API_URL + 'teams/',
        BASE_API_V1_URL,
        AUTHENTICATION: {
            TYPE: BASE_API_V1_URL + 'v5/authentication_type',
            RESET: BASE_API_V1_URL + '3/forgot/password',
            REGISTER: BASE_API_V1_URL + '4/auth/register',
            VALIDATE: BASE_API_V1_URL + '/auth/validateCode',
            LOGIN: BASE_API_V1_URL + '3/auth/login',
            USER_ID: BASE_API_V1_URL + 'v5/userid',
            CHECK_LOGIN: BASE_API_URL + 'login/check_login',
            LOGIN_JWT: BASE_API_URL + 'login/jwt',
            SSO_VALIDATE: BASE_API_URL + 'login/sso_validate',
            SSO_REFRESH: BASE_API_URL + 'login/sso_refresh',
            SSO_LOGOUT: BASE_API_URL + 'login/sso_logout'
        },
        REPORTS: {
            FOLDERS: `${BASE_API_V1_URL}v6/folders`,
            FLOORPLANS: `${BASE_API_V1_URL}v5/folder/${REPORTS_FOLDER_ID}/floorplans`,
            MARKERS_BY_FLOORPLAN_ID: `${BASE_API_V1_URL}v5/floorplan/${REPORTS_FLOORPLAN_ID}/markers`,
            MARKERS: BASE_API_V1_URL + 'v5/markers',
            MARKER_BY_ID: `${BASE_API_V1_URL}scannedMarker`,
            TEAMS_FOR_USER: `${BASE_API_URL}users/${REPORTS_USER_ID}/teams`,
            TEAM_MODULE_GROUPS: BASE_API_V1_URL + 'moduleGroupsForTeam',
            TEAM_REPORTS: BASE_API_V1_URL + `v5/teams/${TEAM_REPORTS_TEAM_ID}/reports`,
            TEAM_REPORTS_SEARCH: BASE_API_V1_URL + `v5/teams/${TEAM_REPORTS_TEAM_ID}/reports/search/${TEAM_REPORTS_SEARCH}`,
            TEAM_REPORTS_EXPORT,
            TEAMS_MARKERS: BASE_API_V1_URL + `v5/teams/${TEAM_REPORTS_TEAM_ID}/markers`,
            TEAMS_MARKER_SEARCH: BASE_API_V1_URL + `v5/teams/${TEAM_REPORTS_TEAM_ID}/markers/search`,
            FLOORPLANS_MARKER_SEARCH: `${BASE_API_V1_URL}v5/floorplan/${REPORTS_FLOORPLAN_ID}/markers/search`,
            MODULE_GROUPS_BY_USER_AND_MARKER: BASE_API_V1_URL + 'moduleGroupsByUserAndMarker',
            MODULES_BY_MODULE_GROUP_AND_MARKER: BASE_API_V1_URL + 'modulesByModuleGroupAndMarker',
            CREATE_REPORT: BASE_API_V1_URL + 'createReport',
            REPORT_AGREE: BASE_API_V1_URL + 'agree',
            // REPORT_BY_USER: BASE_API_V1_URL + 'reportsByUser',
            REPORT_BY_USER: BASE_API_V1_URL + 'reports',
            REPORT_DETAIL: BASE_API_V1_URL + 'report',
            REPORT_DETAIL_IMAGES: BASE_IMAGE_API_URL + `reports/${REPORTS_REPORT_ID}/images`,
            REPORT_GET_COMMENTS: BASE_API_V1_URL + 'comments',
            REPORT_POST_COMMENT: BASE_API_V1_URL + 'comment',
            REPORT_CHANGE_STATUS: BASE_API_V1_URL + 'changeReportStatus',
            REPORT_CHANGE_CATEGORY: `${BASE_API_V1_URL}v5/teams/${TEAM_REPORTS_TEAM_ID}/reports/${REPORTS_REPORT_ID}/change_module_group`,
            CHANGE_MODULE_CHECK_BY_TEAM: `${BASE_API_V1_URL}v5/teams/${TEAM_REPORTS_TEAM_ID}/feature_switch/change_module`,
            REPORT_ASSIGN: BASE_API_V1_URL + 'assignReport',
            REPORT_DELETE: BASE_API_V1_URL + `v5/reports/${REPORTS_REPORT_ID}`,
            REPORT_PICTURE: `${BASE_API_URL}reports/${REPORTS_REPORT_ID}/photo`,
            REPORT_DETAIL_PICTURE: `${BASE_API_URL}markers/${MARKER_TOKEN}/photo`,
            NEW_FEEDBACK_FOR_USER: `${BASE_API_V1_URL}users/${REPORTS_USER_ID}/reports/new_feedback`,
            SUBMIT_FEEDBACK: `${BASE_API_V1_URL}submitFeedback`
        },
        CAPTCHA_VERIFY_URL,
        CAPTCHA: {
            BASE_URL: CAPTCHA_VERIFY_URL,
            CLIENT_TYPE: {
                ADMIN_TOOL: 'ad',
                PORTAL2: 'p2',
                PORTAL3: 'p3'
            }
        },
        TABLEAU: {
            BASE_URL: TABLEAU_BASE_URL,
            BASE_API_URL: TABLEAU_BASE_API_URL,
            REFRESH_RATE: TABLEAU_REFRESH_RATE
        },
        ANALYTICS: {
            GET_TAB: BASE_API_URL + `visualanalytics/tabs/${ANALYTICS_TAB_ID}`
        }
    }
};
