import * as React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions/transition';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';

export enum DialogResult {
    Unknown = 0,
    Ok,
    Cancel
}

const styles = (theme: Theme) => createStyles({
    cancelButton: {
        color: theme.ccPalette.cancel.main
    }
});

interface Props extends WithStyles<typeof styles> {
    buttonOkLabel: string;
    buttonCancelLabel: string;
    className: string;
    contentText: string;
    open: boolean;
    titleText: string;
    onDialogResult: (result: DialogResult) => void;
    fullscreen?: boolean;
}

class SimpleDialog extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
        buttonOkLabel: 'Ok',
        buttonCancelLabel: 'Cancel',
        className: ''
    };
    
    createTransition = (props: unknown, ref?: React.Ref<unknown>) => {
        // eslint-disable-next-line react/no-children-prop, @typescript-eslint/ban-types
        return <Slide children={<></>} ref={ref} direction="up" {...props as object} />;
    };

    invokeModalResult = (dialogResult: DialogResult) => {
        const { onDialogResult } = this.props;
        if(onDialogResult) {
            onDialogResult(dialogResult);
        }
    };

    onHandleClose = () => {
        this.invokeModalResult(DialogResult.Unknown);
    };

    onOkButtonClicked = () => {
        this.invokeModalResult(DialogResult.Ok);
    };

    onCancelButtonClicked = () => {
        this.invokeModalResult(DialogResult.Cancel);
    };

    public render() {
        const {
            classes,
            open,
            buttonOkLabel,
            buttonCancelLabel,
            contentText,
            titleText,
            fullscreen
        } = this.props;

        // Fixes a bug in Material UI  library that keeps the popup's overlay
        // absorbing the mouse events once closed.
        if (!open) {
            return '';
        }

        return (
            <Dialog
                open={open}
                TransitionComponent={React.forwardRef<unknown, TransitionProps>(this.createTransition)}
                keepMounted={false}
                onClose={this.onHandleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullScreen={fullscreen}
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {titleText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        (buttonCancelLabel && buttonCancelLabel.length > 0) ?
                            <Button
                                className={classes.cancelButton}
                                onClick={this.onCancelButtonClicked}
                            >
                                {buttonCancelLabel}
                            </Button>
                            : ''
                    }
                    {
                        (buttonOkLabel && buttonOkLabel.length > 0) ?
                            <Button onClick={this.onOkButtonClicked} color="primary">
                                {buttonOkLabel}
                            </Button>
                            : ''
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

const MUIComponent = withStyles(styles)(SimpleDialog);
export {MUIComponent as SimpleDialog};
