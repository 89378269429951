/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { API } from '../../constants';
import { RootStore } from '../../stores/root.store';
import { Typography } from '@mui/material';

// TODO: Enrich this interface as needed
interface TableauViz {
    refreshDataAsync: ()=> void
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    tableauContainer: {
        paddingTop: '0.5em',
        width: '100%'
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        margin: '0 2em'
    },
    errorMessage: {
        width: '100%',
        color: 'red',
        fontWeight: 400,
        textAlign: 'center',
        margin: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface States {
    errorMessage: string;
}

@inject('rootStore')
@observer
class AnalyticsTab extends React.Component<Props, States> {
    public static defaultProps = {
    };
    
    state = {
        errorMessage: ''
    };

    isComponentInitialized = false;
    vizContainer: React.RefObject<HTMLDivElement> = React.createRef();
    tableauViz: TableauViz | null = null;
    timer?: number;

    // componentDidMount() {
    //     this.initializeTableauPage();
    // }

    public componentWillUnmount() {
        this.stopTimer();
    }

    initializeTableauPage = () => {
        if (this.isComponentInitialized) {
            return;
        }

        const {
            rootStore: {
                managerStore: {
                    currentAnalyticsTab
                }
            }
        } = this.props;

        if (!currentAnalyticsTab) {
            return;
        }

        this.isComponentInitialized = true;

        let ready = false;
        const initVisualization = this.initVisualization;
        const script: HTMLScriptElement = document.createElement('script');
        script.src = API.URL.TABLEAU.BASE_API_URL as string;
        script.async = true;
        script.onload = function() {
            // console.log( this.readyState ); //uncomment this line to see which ready states are called.
            if ( !ready && (!(this as any).readyState || (this as any).readyState === 'complete') )
            {
                ready = true;
                initVisualization();
            }
        };
        document.body.appendChild(script);
    };

    initVisualization = () => {
        const {
            rootStore: {
                managerStore: {
                    currentAnalyticsTab
                }
            }
        } = this.props;

        if (!currentAnalyticsTab) {
            return;
        }

        const {
            url: vizUrl,
            refreshRate
        } = currentAnalyticsTab;

        if (!vizUrl) {
            return;
        }

        const options = {
            hideTabs: false,
            hideToolbar: false,
            width: '100%',
            height: '100%',
            onFirstInteractive: () => {
                if ((this.tableauViz) && (refreshRate > 0)) {
                    this.setRefreshTimer(this.tableauViz, refreshRate);
                }
            }
        };

        const vizContainer = this.vizContainer.current;
        const tableau = (window as any).tableau;
        const viz: TableauViz = new tableau.Viz(vizContainer, vizUrl, options);
        this.tableauViz = viz;
    };

    public render() {
        const {
            classes,
            rootStore: {
                managerStore: {
                    currentAnalyticsTab,
                    errorAnalyticsTab
                }
            }
        } = this.props;

        if (errorAnalyticsTab) {
            return (
                <div
                    className={classes.errorContainer}
                >
                    <Typography
                        className={classes.errorMessage}
                        variant="h2"
                    >
                        {errorAnalyticsTab}
                    </Typography>
                    <Typography
                        variant="h4"
                    >
                        Please reload the page.
                    </Typography>
                    <Typography
                        variant="h4"
                    >
                        If the problem persists, please contact support at sayhello@crowdcomfort.com
                    </Typography>
                </div>
            );
        }

        const keyId = currentAnalyticsTab ? currentAnalyticsTab.id : 'viz-00';
        this.initializeTableauPage();

        return (
            <div
                key={keyId}
                className={classes.tableauContainer}
                ref={this.vizContainer}
            />
        );
    }

    private setRefreshTimer(viz: TableauViz, refreshRate: number) {
        if (!viz) {
            return;
        }

        this.timer = window.setInterval(this.refreshAllDataSources(viz), refreshRate*1000);
    }

    private refreshAllDataSources = (viz: TableauViz) => () => {
        viz.refreshDataAsync();
    };

    private stopTimer = () => {
        const { timer } = this;
        if (timer) {
            window.clearInterval(timer);
        }

        this.timer = undefined;
    };
}

const MUIComponent = withStyles(styles)(AnalyticsTab);
export { MUIComponent as AnalyticsTab };
