import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
    Cancel,
    KeyboardArrowLeft,
    PinDrop
} from '@mui/icons-material';
import memoize from 'memoize-one';
import * as React from 'react';
import { CCSpinner } from '../../shared/components/cc-spinner';
import Filter from '../../shared/components/icons/filter';
import FilterClear from '../../shared/components/icons/filter-clear';
import {
    NavigationList,
    NavigationListItem
} from '../navigation-list';

const FILTER_APPLIED_TEXT = '(Filter Applied)';

export interface LocationFilterUtilityFunctions {
    resetFilter: () => void;
}

export interface OnDataRequestProps {
    data: ReadonlyArray<NavigationListItem>,
    total: number
}

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative'
    },
    disabled: {}, // used for reference
    spinner: {
        margin: 'auto'
    },
    headerContainer: {
        display: 'flex',
        width: '100%',
        minHeight: '4em',
        '&:hover $cancelIcon': {
            fill: theme.palette.grey[500]
        }
    },
    headerTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
    },
    headerText: {
        margin: 'auto 0',
        fontSize: '0.9em',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginLeft: '-0.5em',
        maxWidth: '14em',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    headerTextFilter: {
        color: theme.palette.grey[500],
        margin: 0,
        fontSize: '0.7em',
        fontWeight: 'bold',
        fontStyle: 'italic',
        textAlign: 'center'
    },
    errorText: {
        color: 'red',
        fontSize: '1em',
        textAlign: 'justify',
        margin: '0 0.5em 0.5em'
    },
    headerButton: {
        fontSize: '1em',
        color: '#000000',
        width: '2em',
        padding: '0.5em 0',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    backIcon: {
        fontSize: '3em'
    },
    backText: {
        margin: '0'
    },
    filterButton: {
        margin: '0 0 0 auto',
        padding: '0',
        '&:hover':{
            backgroundColor: 'transparent'
        }
    },
    pinDropIcon: {
        margin: 'auto 0.4em auto 0.1em'
    },
    filterIcon: {
        width: '0.8em',
        marginLeft: '-1em',
        '&$disabled': {
            fill: theme.palette.grey[500]
        }
    },
    cancelIcon: {
        fontSize: '1.2em',
        margin: '0 0.2em 0 0',
        fill: 'transparent'
    },
    clearButton: {
        padding: 0,
        fontSize: '1em',
        '&:hover':{
            backgroundColor: 'transparent'
        }
    },
    divider: {
        width: '100%'
    },
    panel:{
        margin: 0
    },
    panelSummary: {
        padding: 0
    },
    panelSummaryContent: {
        margin: 0,
        '&$panelSummaryContentExpanded': {
            margin: 0,
            '& $headerText': {
                maxWidth: '12.5em'
            }
        },
        '& > :last-child': {
            padding: 0
        }
    },
    panelSummaryContentExpanded: {},
    panelDetails: {
        flexDirection: 'column',
        padding: 0,
        overflow: 'auto',
        minHeight: '3.5em',
        maxHeight: '10.8em'
    },
    navigationList: {
        overflow: 'auto',
        width: '100%'
    },
    filterMenu: {
        marginTop: '-1em',
        marginLeft: 0,
        '& ul': {
            padding: 0,
            '& li:hover': {
                backgroundColor: 'transparent'
            }
        }
    },
    filterMenuItem: {
        height: '4em',
        padding: '0 0.5em'
    },
    filterTextField: {
        margin: 0
    },
    filterTextFieldLabel: {
        fontSize: '1em'
    },
    filterTextFieldInputContainer: {
        fontSize: '1em'
    },
    filterTextFieldInput: {
        padding: '0.6em 0.5em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    enabled: boolean;
    filterPlaceholderText?: string;
    folderIsSelectable?: boolean
    data?: NavigationListItem;
    loading: boolean;
    initialSelectedItem?: NavigationListItem;
    onClick?: (item: NavigationListItem | undefined) => void;
    onComponentMount?: (utilities: LocationFilterUtilityFunctions) => void;
    onDataRequest?: (item: NavigationListItem) => Promise<OnDataRequestProps>;
    onNextPageLoad?: (item: NavigationListItem | undefined) => Promise<OnDataRequestProps>;
}

interface States {
    dataPath: NavigationListItem[];
    loading: boolean;
    isFilterMenuOpen: boolean;
    isPanelExpanded: boolean;
    filterTerm: string;
    selectedItem: NavigationListItem | undefined;
    errorText: string;
}
const DEFAULT_STATES = {
    dataPath: [] as NavigationListItem[],
    loading: false,
    isFilterMenuOpen: false,
    isPanelExpanded: false,
    filterTerm: '',
    page: 0,
    selectedItem: undefined as NavigationListItem | undefined,
    errorText: ''
};

class LocationFilter extends React.Component<Props, States> {
    public static defaultProps = {
        enabled: true,
        loading: false,
        folderIsSelectable: false
    };

    state ={ ...DEFAULT_STATES };

    filterData = memoize((data: ReadonlyArray<NavigationListItem>, filterTerm: string) => {
        if (!filterTerm) {
            return data;
        }

        return data.filter((value: NavigationListItem)=> {
            return value.label.toLocaleLowerCase().indexOf(filterTerm.toLocaleLowerCase()) >= 0;
        });
    });
    
    menuAnchorElement: HTMLElement | null = null;

    componentDidMount() {
        const { onComponentMount, initialSelectedItem } = this.props;
        if (onComponentMount) {
            onComponentMount({
                resetFilter: this.resetFilter
            });
        }

        if (initialSelectedItem) {
            this.setState({selectedItem: initialSelectedItem});
        }
    }

    resetFilter = () => {
        this.setState({ ...DEFAULT_STATES });
    };

    closeMenu = () => {
        this.setState({isFilterMenuOpen: false});
    };

    getCurrentNavigationItem = (): NavigationListItem | undefined => {
        const { data } = this.props;
        const { dataPath } = this.state;
        const dataPathLength = dataPath.length;
        return dataPathLength > 0 ? dataPath[dataPathLength-1] : data;
    };

    onFilterIconClicked = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const { loading } = this.state;
        if (loading) {
            return;
        }

        this.menuAnchorElement = event.currentTarget;
        this.setState({isFilterMenuOpen: true});
    };

    onNavigationListArrowClicked = (item: NavigationListItem) => {
        const { isFolder } = item;
        if (!isFolder) {
            return;
        }

        const { dataPath } = this.state;
        // If the folder has already the data in it
        if ((item.folderData) && (item.folderDataTotal > 0)) {
            dataPath.push(item);
            this.setState({
                dataPath,
                loading: false,
                selectedItem: undefined,
                filterTerm: '',
                errorText: ''
            });
            return;
        }

        const { onDataRequest } = this.props;
        // Otherwise we request the data using
        // the callback onDataRequest if it exists
        if (!onDataRequest) {
            return;
        }

        onDataRequest(item)
            .then((results: OnDataRequestProps) => {
                item.folderData = results.data;
                item.folderDataTotal = results.total;
                dataPath.push(item);
                this.setState({
                    dataPath,
                    loading: false,
                    filterTerm: ''
                });
                this.onClearButtonClicked();
            })
            .catch((error) => {
                item.folderData = [];
                item.folderDataTotal = 0;
                dataPath.push(item);
                this.setState({
                    dataPath,
                    loading: false,
                    filterTerm: '',
                    errorText: error ? error : 'Error loading data.',
                });
            });
        this.setState({
            loading: true,
            errorText: ''
        });
    };

    onNavigationListClicked = (item: NavigationListItem) => {
        const {
            folderIsSelectable,
            onClick
        } = this.props;
        const { selectedItem: selectedItemState} = this.state;
        const { isFolder } = item;
        // If the item is a folder and the folder is not a selectable item
        // We behave the same way like the user has clicked on the arrow
        if (!folderIsSelectable && isFolder) {
            this.onNavigationListArrowClicked(item);
            return;
        }

        // If the item is a leaf (not a folder) or the folder is selectable
        // it notifies the caller that a leaf was clicked
        const isItemAlreadySelected = (selectedItemState === item);
        const selectedItem = isItemAlreadySelected ? undefined : item;
        this.setState({ selectedItem });
        if (!isItemAlreadySelected) {
            this.setState({ 
                isPanelExpanded: false,
                filterTerm: ''
            });
        }

        if (onClick) {
            onClick(selectedItem);
        }
    };

    onNavigationListLoadNextPage = (): Promise<void> => {
        const { onNextPageLoad } = this.props;
        if (!onNextPageLoad) {
            return Promise.resolve();
        }
        const currentNavigationItems: NavigationListItem | undefined =
            this.getCurrentNavigationItem();
        return new Promise<void>((resolve, reject) => {
            onNextPageLoad(currentNavigationItems)
                .then((results: OnDataRequestProps) => {
                    const { data: rootNode } = this.props;
                    const { dataPath } = this.state;
                    const dataPathLength = dataPath.length;
                    const {
                        data,
                        total
                    } = results;
                    const currentElement: NavigationListItem =
                        (dataPathLength > 0) ? dataPath[dataPathLength-1] : rootNode as NavigationListItem;
                    const { folderData } = currentElement;
                    currentElement.folderData = folderData ? [...(folderData), ...data] : [...data];
                    currentElement.folderDataTotal = total;
                    if (dataPathLength > 0) {
                        dataPath[dataPathLength-1] = currentElement;
                        this.setState({dataPath});
                    }
                    return resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    onBackButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const dataPath = this.state.dataPath.slice(0);
        dataPath.pop();
        this.setState({
            dataPath,
            filterTerm: '',
            errorText: ''
        });

        this.onClearButtonClicked();
    };

    onClearButtonClicked = () => {
        this.setState({ selectedItem: undefined });
        const { onClick } = this.props;
        if (onClick) {
            onClick(undefined);
        }
    };

    onExpansionPanelChanged = (event: React.ChangeEvent<unknown>, isPanelExpanded: boolean) => {
        this.setState({isPanelExpanded});
    };

    onExpansionPanelBlurred = (event: React.FocusEvent<HTMLDivElement>) => {
        const {
            dataPath,
            selectedItem
        } = this.state;

        if ((selectedItem || (dataPath.length === 0)) && !(event.relatedTarget)) {
            this.setState({isPanelExpanded: false});
        }
    };

    onTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({filterTerm: event.target.value});
    };
    
    public render() {
        const {
            className,
            classes,
            filterPlaceholderText,
            loading: loadingProp,
            enabled
        } = this.props;
        const {
            dataPath,
            loading: loadingState,
            isFilterMenuOpen,
            isPanelExpanded,
            filterTerm,
            selectedItem,
            errorText
        } = this.state;
        const dataPathLength = dataPath.length;
        const loading = loadingProp || loadingState;
        const placeholderText = filterPlaceholderText ? filterPlaceholderText : 'Filter Terms';
        const showBackButton = isPanelExpanded && (dataPathLength > 0);

        const currentNavigationItems: NavigationListItem | undefined = 
            this.getCurrentNavigationItem();
        let data: ReadonlyArray< NavigationListItem>;
        let hasNextPage: boolean;
        if (currentNavigationItems) {
            const {folderData, folderDataTotal} = currentNavigationItems;
            data = folderData ? folderData : [];
            hasNextPage = data.length < folderDataTotal;
        } else {
            data = [];
            hasNextPage = false;
        }
        
        const rootClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        return (
            <div className={rootClasses}>
                <Accordion
                    className={classes.panel}
                    expanded={isPanelExpanded} 
                    onChange={this.onExpansionPanelChanged}
                    onBlur={this.onExpansionPanelBlurred}
                    disabled={!enabled}
                >
                    <AccordionSummary
                        className={classes.panelSummary}
                        classes={{
                            content: classes.panelSummaryContent,
                            expanded: classes.panelSummaryContentExpanded
                        }}
                    >
                        <div className={classes.headerContainer}>
                            { !showBackButton ? 
                                <PinDrop className={classes.pinDropIcon}/>
                                : <IconButton
                                    className={classes.headerButton}
                                    disableRipple={true}
                                    onClick={this.onBackButtonClicked}
                                >
                                    <KeyboardArrowLeft className={classes.backIcon} />
                                </IconButton>
                            }
                            <div className={classes.headerTextContainer}>
                                <Typography 
                                    className={`${classes.headerText} ${showBackButton ? classes.backText : ''}`}
                                    variant="h3"
                                >
                                    { showBackButton ? 
                                        dataPath[dataPathLength-1].label
                                        : selectedItem ?
                                            selectedItem.label
                                            : 'ALL LOCATIONS'
                                    }
                                </Typography>
                                { filterTerm ?
                                    <Typography
                                        className={classes.headerTextFilter}
                                        variant="h4"
                                    >
                                        { FILTER_APPLIED_TEXT }
                                    </Typography>
                                    : ''
                                }
                            </div>
                            { !showBackButton && selectedItem ?
                                <IconButton
                                    className={classes.clearButton}
                                    disableRipple={true}
                                    onClick={this.onClearButtonClicked}
                                >
                                    <Cancel className={classes.cancelIcon}/>
                                </IconButton>
                                : ''
                            }
                            { isPanelExpanded ? 
                                <IconButton
                                    className={classes.filterButton}
                                    onClick={this.onFilterIconClicked}
                                >
                                    { filterTerm ?
                                        <FilterClear className={`${classes.filterIcon}${loading ? ` ${classes.disabled}` : ''}`}/>
                                        : <Filter className={`${classes.filterIcon}${loading ? ` ${classes.disabled}` : ''}`}/>
                                    }
                                </IconButton>
                                : ''
                            }
                        </div>
                    </AccordionSummary>
                    { errorText ?
                        <Typography
                            className={classes.errorText}
                            variant="h3"
                        >
                            {errorText}
                        </Typography>
                        :
                        <AccordionDetails
                            className={classes.panelDetails}
                        >
                            <Divider className={classes.divider} />
                            <NavigationList
                                className={classes.navigationList}
                                data={this.filterData(data, filterTerm)}
                                hasNextPage={hasNextPage}
                                selectedItem={selectedItem}
                                onClick={this.onNavigationListClicked}
                                onArrowClick={this.onNavigationListArrowClicked}
                                onNextPageLoad={this.onNavigationListLoadNextPage}
                            />
                        </AccordionDetails>
                    }
                </Accordion>
                <Menu
                    anchorEl={this.menuAnchorElement}
                    // getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{
                        paper: classes.filterMenu
                    }}
                    open={isFilterMenuOpen}
                    onClose={this.closeMenu}
                >
                    <MenuItem
                        className={classes.filterMenuItem}
                        selected={false}
                        disableRipple={true}
                    >
                        <TextField
                            id="standard-search"
                            className={classes.filterTextField}
                            label="Filter"
                            placeholder={placeholderText}
                            type="search"
                            margin="normal"
                            variant="outlined"
                            value={filterTerm}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.filterTextFieldLabel
                            }}
                            InputProps={{
                                className: classes.filterTextFieldInputContainer,
                                classes: {
                                    input: classes.filterTextFieldInput
                                }
                            }}
                            onChange={this.onTextFieldChanged}
                        />
                    </MenuItem>
                </Menu>
                <CCSpinner
                    className={classes.spinner}
                    blockPageScroll={false}
                    loading={loading}
                    overlayVisible={true}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LocationFilter);
export { MUIComponent as LocationFilter };
