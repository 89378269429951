import * as React from 'react';

import { Button, Divider, Popover, Typography } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Calendar from '../../shared/components/icons/calendar';
import {
    CCDatePicker,
    CCDatePickerButtonType,
    CCDatePickerDateRange,
} from './cc-date-picker';
import {
    withMediaQuery,
    WithMediaQuery,
} from '../../shared/components/with-media-query';
import { RESPONSIVE_BREAKPOINTS } from '../../shared/utils';
import { isMobile } from '../../shared/utils/browser-check';

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        isMobile: {},
        calendarButton: {
            width: '12em',
            height: '3.5em',
            padding: '0.5em',
        },
        calendarButtonLabel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        popoverRootContainer: {
            '$isMobile &': {
                top: '0!important', // Forced to use 'important' because these two properties
                left: '0!important', // are injected in the style property of the DOM element
                minWidth: '100%',
                minHeight: '100%',
                borderRadius: 0,
            },
            [theme.breakpoints.between(
                'xs',
                theme.breakpoints.values.iPhone8_Landscape
            )]: {
                top: '0!important', // Forced to use 'important' because these two properties
                left: '0!important', // are injected in the style property of the DOM element
                minWidth: '100%',
                minHeight: '100%',
                borderRadius: 0,
            },
        },
        popover: {
            fontSize: '0.8em',
        },
        datePicker: {
            marginRight: '1.8em',
        },
        dayPicker: {
            minHeight: '18em',
            flex: 1,
        },
        calendarIcon: {
            width: '2.24em',
            marginRight: '1em',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            margin: '1em 0',
            '& Button': {
                fontSize: '1em',
                marginRight: '1em',
            },
        },
        dateRangeTitle: {
            flex: '1 1 0',
            fontSize: '0.9em',
            lineHeight: '1.4',
            textAlign: 'left',
            margin: '0 auto',
        },
        textNoticeContainer: {
            flex: 'auto',
            maxWidth: '32em',
            maxHeight: '2.9em',
            paddingLeft: '1em',
            marginRight: 'auto',
            overflow: 'hidden',
        },
        textNotice: {
            color: 'red',
            fontSize: '1em',
        },
    });

interface Props extends WithMediaQuery<WithStyles<typeof styles>> {
    className?: string;
    dateRange?: CCDatePickerDateRange | undefined;
    onDateRangeChange?: (dateRange: CCDatePickerDateRange) => void;
}

interface States {
    dateRange: CCDatePickerDateRange | undefined;
    isPopupOpen: boolean;
}

class CCDatePickerButton extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    initialState = {
        dateRange: undefined as CCDatePickerDateRange | undefined,
        isPopupOpen: false,
    };

    state = Object.assign({}, this.initialState);

    popupAnchorElement: HTMLElement | null = null;
    lastDateRange = undefined as CCDatePickerDateRange | undefined;

    getTextFromDateRange(dateRange: CCDatePickerDateRange | undefined): string {
        if (dateRange && dateRange.buttonType !== CCDatePickerButtonType.All) {
            switch (dateRange.buttonType) {
                case CCDatePickerButtonType.Last30Days:
                    return 'Last 30 Days';
                case CCDatePickerButtonType.Last7Days:
                    return 'Last 7 Days';
                case CCDatePickerButtonType.LastYear:
                    return 'Last Year';
                case CCDatePickerButtonType.Today:
                    return 'Today';
                case CCDatePickerButtonType.None:
                default: {
                    const { from, to } = dateRange;
                    const formattedFromDate = !from
                        ? ''
                        : from.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        });
                    const formattedToDate = !to
                        ? ''
                        : to.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        });
                    if (!!from && !!to) {
                        return formattedFromDate === formattedToDate
                            ? formattedFromDate
                            : `${formattedFromDate} - ${formattedToDate}`;
                    }
                }
            }
        }

        return 'All Dates';
    }

    onDatePickerButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
        if (!this.popupAnchorElement) {
            this.popupAnchorElement = event.currentTarget;
        }
        this.setState({ isPopupOpen: true });
    };

    onDateRangeChanged = (dateRange: CCDatePickerDateRange) => {
        this.lastDateRange = dateRange;
    };

    onClose = () => {
        this.setState({ isPopupOpen: false });
    };

    onApplyClicked = () => {
        const { onDateRangeChange } = this.props;
        if (!onDateRangeChange) {
            return;
        }

        let dateRange = Object.assign({}, this.lastDateRange);
        if (!dateRange) {
            dateRange = {
                buttonType: CCDatePickerButtonType.All,
            } as CCDatePickerDateRange;
        }
        onDateRangeChange(dateRange);
        this.setState(Object.assign({}, this.initialState));
        this.onClose();
    };

    public render() {
        const {
            className,
            classes,
            dateRange: dateRangeProp,
            mediaQuery,
        } = this.props;
        const { dateRange: dateRangeState, isPopupOpen } = this.state;
        const dateRange = dateRangeState ? dateRangeState : dateRangeProp;
        const rootClasses = `${classes.root}${
            className ? ` ${className}` : ''
        }${isMobile() ? ` ${classes.isMobile}` : ''}`;
        const timezone = Intl.DateTimeFormat('en-US', {
            timeZoneName: 'short',
        }).formatToParts(new Date())[6].value;
        const calendarBtnClasses = {
            label: classes.calendarButtonLabel,
        };

        return (
            <div className={rootClasses}>
                <Button
                    aria-owns={isPopupOpen ? 'date-picker-popper' : undefined}
                    aria-haspopup="true"
                    className={classes.calendarButton}
                    classes={calendarBtnClasses}
                    variant="outlined"
                    color="info"
                    onClick={this.onDatePickerButtonClicked}
                >
                    <Calendar className={classes.calendarIcon} />
                    <span className={classes.dateRangeTitle}>
                        {this.getTextFromDateRange(dateRange)}
                    </span>
                </Button>
                <Popover
                    id="date-picker-popper"
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverRootContainer,
                    }}
                    anchorEl={this.popupAnchorElement}
                    open={isPopupOpen}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <CCDatePicker
                        className={classes.datePicker}
                        classes={{ dayPicker: classes.dayPicker }}
                        dateRange={dateRange}
                        numberOfMonths={mediaQuery ? 1 : 2}
                        onDateRangeChange={this.onDateRangeChanged}
                    />
                    <Divider />
                    <div className={classes.buttonContainer}>
                        <div className={classes.textNoticeContainer}>
                            <Typography
                                className={classes.textNotice}
                                component="h1"
                                variant="h6"
                            >
                                Note: The time used in this filter will be based
                                on your local time zone ({timezone})
                            </Typography>
                        </div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.onApplyClicked}
                        >
                            Apply
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={this.onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </Popover>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(
    withMediaQuery(`(max-width:${RESPONSIVE_BREAKPOINTS.sm}px)`)(
        CCDatePickerButton
    )
);
export { MUIComponent as CCDatePickerButton };
