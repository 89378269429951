// "folder_id": "folder_5ad0e512413c3f3d246c64e0",
// "name": "Marker 60",
// "folder_name": "Markers Nearby Test Site",
// "marker_id": "folder_5ad0e512413c3f3d246c64e0_marker_5ad0e513413c3f3d246c651d"

export interface MarkerModel {
    folder_id: string;
    name: string;
    folder_name: string;
    marker_id: string;
    marker_name?: string;
    floorplan_name?: string;
    floorplan_id?: string;
}

export class Marker {
    static create (model: MarkerModel): Marker {
        const {
            marker_id: id,
            marker_name: markerName,
            name,
            folder_name: folderName,
            folder_id: folderId,
            floorplan_name: floorplanName,
            floorplan_id: floorplanId
        } = model;

        return new Marker(id, name ? name : markerName ? markerName : '', folderName, folderId, floorplanName ? floorplanName : '', floorplanId ? floorplanId : '');
    }
    constructor (
        public readonly id: string,
        public readonly name: string,
        public readonly folderName: string,
        public readonly folderId: string,
        public readonly floorplanName: string,
        public readonly floorplanId: string
    ){}
}