import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { inject } from 'mobx-react';
import {
    RouterView,
    ViewMap
} from 'mobx-state-router';
import 'typeface-roboto';
import { Home } from './features/home/home';
import { QRScan } from './features/home/qr-scan';
import { Login } from './features/login/login';
import { NotFoundPage } from './features/not-found-page/not-found-page';
import { RootStore } from './stores/root.store';
import { ROUTE_NAMES } from './stores/routes';

const viewMap: ViewMap = {
    [ROUTE_NAMES.NOT_FOUND]: <NotFoundPage />,
    [ROUTE_NAMES.HOME]: <Home />,
    [ROUTE_NAMES.REPORTS]: <Home />,
    [ROUTE_NAMES.ANALYTICS]: <Home />,
    [ROUTE_NAMES.REDIRECT]: <Home />,
    [ROUTE_NAMES.LOGIN]: <Login />,
    [ROUTE_NAMES.CREATE_ACCOUNT]: <Login />,
    [ROUTE_NAMES.QR_SCAN]: <QRScan />
};

const styles = (theme: Theme) => createStyles({
    '@global': {
        html: {
            height: '100%',
            boxSizing: 'border-box'
        },
        '*, *:before, *:after': {
            boxSizing: 'inherit'
        },
        body: {
            height: '100%',
            margin: 0,
            padding: 0,
            background: theme.palette.background.default,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.palette.text.primary,

            // Helps fonts on OSX look more consistent with other systems
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',

            // Use momentum-based scrolling on iOS devices
            WebkitOverflowScrolling: 'touch'
        },
        '#root': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: 0
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

@inject('rootStore')
class Shell extends React.Component< Props > {
    public static defaultProps: Partial<Props> = {
    };

    public render() {
        const {
            classes,
            rootStore: {routerStore}
        } = this.props;

        return (
            <div className={classes.root}>
                <RouterView
                    routerStore={routerStore}
                    viewMap={viewMap}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Shell);

export {MUIComponent as Shell};

