import * as React from 'react';

import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import memoize from 'memoize-one';
import {
    PagingBuffer,
    PagingBufferPageBoundary as PageBoundary
} from '../../utils/paging-buffer';
import {
    SimpleList,
    SimpleListItem
} from '../simple-list';
import { SimplePagination } from '../simple-pagination';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '&.container' : {
            overflow: 'inherit',
            height: 'inherit'
        }
    },
    pagination: {},
    paginationToolbar: {}
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    disabled: boolean,
    rowsPerPage: number;
    items: SimpleListItem[];
    isLoading: boolean;
    totalItems?: number;
    offset: number;
    keepItemSelected: boolean; // Keep the item selected after click
    canDeleteListItem: boolean;
    noItemsLabel?: string;
    noLabelText?: string;
    selectedItemId?: string;
    customItemIcon?: React.ReactChild;
    onListItemClick?: (listItem: SimpleListItem) => void;
    onDeleteItemClick?: (listItem: SimpleListItem) => void;
    onCustomItemClick?: (listItem: SimpleListItem) => void;
    onPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
}


interface States {
    page: number;
}

class SimpleListPagination extends React.Component<Props, States> {
    public static defaultProps = {
        canDeleteListItem: false,
        disabled: false,
        isLoading: false,
        keepItemSelected: false,
        offset: 0
    };

    state = {
        page: 0
    };

    getPagingBufferInstance = memoize(
        ( items: SimpleListItem[],
            rowsPerPage: number,
            offset: number)
        : PagingBuffer<SimpleListItem> => {
            return new PagingBuffer(items, rowsPerPage, offset, 0);
        }
    );

    componentDidUpdate(prevProps: Props) {
        const {page} = this.state;
        if ((page !== 0) &&
            ((this.props.totalItems !== prevProps.totalItems) ||
            (this.props.items.length !== prevProps.items.length))
        ) {
            this.setState({page: 0});
        }
    }

    onPageChanged = (event: React.MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
        const {
            items,
            rowsPerPage,
            offset,
            onPageOverBoundary
        } = this.props;
        const buffer: PagingBuffer<SimpleListItem> =
            this.getPagingBufferInstance(items, rowsPerPage, offset);
        // Set the new buffer page
        buffer.page = nextPage;
        
        const {
            isUpperBoundaryPassed,
            isLowerBoundaryPassed
        } = buffer;

        // If we hit the buffer's upper boundary or the buffer's lower boundary
        if (isUpperBoundaryPassed || isLowerBoundaryPassed) {
            if (onPageOverBoundary) {
                onPageOverBoundary(
                    isUpperBoundaryPassed ? PageBoundary.Upper : PageBoundary.Lower,
                    nextPage
                ).then(()=>{
                    this.setState({page: nextPage});
                }).catch(() => {
                    return;
                });
            }
        }
        else {
            this.setState({page: nextPage});
        }
    };

    public render() {
        const {
            classes,
            className,
            disabled,
            rowsPerPage,
            items,
            isLoading,
            totalItems,
            offset,
            noItemsLabel,
            keepItemSelected,
            canDeleteListItem,
            onListItemClick,
            onDeleteItemClick,
            onCustomItemClick,
            customItemIcon,
            selectedItemId,
            noLabelText
        } = this.props;
        const { page } = this.state;

        const pagingBuffer =
            this.getPagingBufferInstance(
                items,
                rowsPerPage,
                offset
            );
        pagingBuffer.page = page;
        const bufferedListItems: SimpleListItem[] = pagingBuffer.buffer;
        
        return (
            <div className={`${classes.root} container`}>
                <SimpleList
                    className={className}
                    canDeleteListItem={canDeleteListItem}
                    disabled={isLoading || disabled}
                    isLoading={isLoading}
                    items={bufferedListItems}
                    noItemsLabel={noItemsLabel}
                    keepItemSelected={keepItemSelected}
                    onListItemClick={onListItemClick}
                    onDeleteItemClick={onDeleteItemClick}
                    onCustomItemClick={onCustomItemClick}
                    selectedItemId={selectedItemId}
                    noLabelText={noLabelText}
                    customItemIcon={customItemIcon}
                />
                <SimplePagination
                    className={classes.pagination}
                    classes={{
                        toolbar: classes.paginationToolbar
                    }}
                    count={totalItems ? totalItems : items.length}
                    isLoading={isLoading}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={this.onPageChanged}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(SimpleListPagination);
export {MUIComponent as SimpleListPagination, PageBoundary};
