import {
    Divider,
    Typography
} from '@mui/material';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ModeCommentOutlined } from '@mui/icons-material';
import * as React from 'react';
import { ReportComment } from '../../models/report';
import { DateFormatting } from '../../utils/date-formatting';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        display: 'flex',
        marginBottom: '0.3em'
    },
    titleTimestamp: {
        lineHeight: '1.9em',
        marginLeft: '1em'
    },
    commentContainer: {
        textAlign: 'justify',
        '&.last-comment': {
            marginBottom: '2em'
        }
    },
    commentIcon: {
        width: '0.7em',
        height: '0.7em',
        marginBottom: '-0.1em',
        marginRight: '0.4em'
    },
    commentText: {
        display: 'inline',
        whiteSpace: 'pre-wrap'
    },
    commentImage: {
        maxWidth: 185
    }
});

interface Props extends WithStyles<typeof styles> {
    comment: ReportComment;
    isLastOne?: boolean;
}

class CommentItem extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
    };

    state = {
    };

    public render() {
        const {
            classes,
            comment: {
                comment,
                info: {
                    username
                },
                photo,
                timeStamp
            },
            isLastOne
        } = this.props;
        const timeFormatted = DateFormatting.formatMarkerTimestamp(timeStamp);
        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    <Typography variant="subtitle2">{username}</Typography>
                    <Typography
                        className={classes.titleTimestamp}
                        variant="caption"
                    >
                        {timeFormatted}
                    </Typography>
                </div>
                <div className={`${classes.commentContainer}${isLastOne ? ' last-comment' : ''}`}>
                    <ModeCommentOutlined className={classes.commentIcon} />
                    <Typography
                        className={classes.commentText}
                        variant="body2"
                    >
                        {comment}
                    </Typography>
                </div>
                { photo ?
                    <img
                        className={classes.commentImage}
                        alt="comment"
                        src={photo}
                    />
                    : ''
                }
                { isLastOne === true ? '' : <Divider /> }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(CommentItem);
export { MUIComponent as CommentItem };
