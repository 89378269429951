import * as React from 'react';

import { Button } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import DayPicker, { RangeModifier } from 'react-day-picker';
import { CCTimePicker } from '../cc-time-picker';

export enum ButtonType {
    None = -1,
    All = 0,
    LastYear,
    Last30Days,
    Last7Days,
    Today,
}

export interface CCDatePickerDateRange {
    from: Date;
    to: Date;
    buttonType: ButtonType;
}

const formatDoubleDigit = (digit: string): string => {
    if (digit.length === 1) {
        return `0${digit}`;
    }

    return digit;
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '22em',
            '&, div &': {
                marginRight: '0.6em',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto 0',
            [theme.breakpoints.only('xs')]: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                fontSize: '1.2em',
                margin: '0 1em 2em',
            },
        },
        button: {
            margin: '0.2em 0',
            fontSize: '1em',
            '&$buttonDisabled': {
                color: '#000000',
                backgroundColor:
                    theme.ccPalette.cc_colors.translucent
                        .ccRightsilhouette[700],
            },
        },
        buttonDisabled: {}, // Used for reference
        dayPicker: {
            fontSize: '1em',
            margin: 'auto 0',
            '& .DayPicker-wrapper': {
                display: 'flex',
                flexDirection: 'row-reverse',
            },
            '& .DayPicker-NavBar': {
                position: 'relative',
                margin: '0 auto 0 0',
            },
            '& .DayPicker-Day--today': {
                color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
            },
            '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)':
                {
                    backgroundColor:
                        theme.ccPalette.cc_colors.translucent
                            .ccRightsilhouette[100],
                    color: theme.ccPalette.cc_colors.translucent
                        .ccRightsilhouette[700],
                },
            '&:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
                {
                    backgroundColor:
                        theme.ccPalette.cc_colors.translucent
                            .ccRightsilhouette[300],
                },
            '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover':
                {
                    backgroundColor:
                        theme.ccPalette.cc_colors.translucent
                            .ccRightsilhouette[700],
                },
            '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
                {
                    backgroundColor:
                        theme.ccPalette.cc_colors.solid.ccRightsilhouette.main,
                },
            '& .DayPicker-Day': {
                borderRadius: '0',
            },
            '& .DayPicker-Day--start': {
                borderTopLeftRadius: '50%',
                borderBottomLeftRadius: '50%',
            },
            '& .DayPicker-Day--end': {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '1.6em',
            },
        },
        resetButton: {
            alignSelf: 'flex-end',
            fontSize: '1em',
            height: '2em',
            marginLeft: '1em',
            padding: '1.2em',
            [theme.breakpoints.only('xs')]: {
                alignSelf: 'flex-start',
                marginBottom: '1em',
            },
        },
        timePickerContainer: {
            margin: '0 0 1.5em 0.6em',
            [theme.breakpoints.only('xs')]: {
                fontSize: '1.2em',
                border: `${theme.ccPalette.disabled.light} 1px solid`,
                paddingTop: '1em',
            },
        },
        timePicker: {
            '&:first-child': {
                marginRight: '3em',
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '1.6em',
            },
            '& $timePickerTextField': {
                [theme.breakpoints.only('xs')]: {
                    marginBottom: '1.1em',
                    width: '8.2em',
                },
            },
        },
        timePickerTextField: {
            width: '9.5em',
        },
        timePickerTextFieldLabel: {
            [theme.breakpoints.only('xs')]: {
                fontSize: '1.1em',
            },
        },
        timePickerInput: {
            fontSize: '0.8em',
            [theme.breakpoints.only('xs')]: {
                fontSize: '1.3em',
                marginTop: '0.2em',
            },
        },
    });

interface Props extends WithStyles<typeof styles> {
    numberOfMonths: number;
    className?: string;
    dateRange?: CCDatePickerDateRange | undefined;
    onDateRangeChange?: (dateRange: CCDatePickerDateRange) => void;
}

interface States {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    startTime: string;
    endTime: string;
    selectedButton: ButtonType;
}

class CCDatePicker extends React.Component<Props, States> {
    public static defaultProps = {
        numberOfMonths: 2,
    };

    initialState = {
        fromDate: undefined as Date | undefined,
        toDate: undefined as Date | undefined,
        startTime: '00:00',
        endTime: '23:59',
        selectedButton: ButtonType.All,
    };

    state = Object.assign({}, this.initialState, {
        fromDate:
            !!this.props.dateRange && !!this.props.dateRange.from
                ? this.normalizeDate(this.props.dateRange.from)
                : undefined,
        toDate:
            !!this.props.dateRange && !!this.props.dateRange.to
                ? this.normalizeDate(this.props.dateRange.to)
                : undefined,
        startTime:
            !!this.props.dateRange && !!this.props.dateRange.from
                ? `${formatDoubleDigit(
                    this.props.dateRange.from.getHours().toString()
                )}:${formatDoubleDigit(
                    this.props.dateRange.from.getMinutes().toString()
                )}`
                : this.initialState.startTime,
        endTime:
            !!this.props.dateRange && !!this.props.dateRange.to
                ? `${formatDoubleDigit(
                    this.props.dateRange.to.getHours().toString()
                )}:${formatDoubleDigit(
                    this.props.dateRange.to.getMinutes().toString()
                )}`
                : this.initialState.endTime,
    });

    // Normalizes all the date to be with no time
    normalizeDate(date: Date): Date {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0,
            0
        );
    }

    addStateTimeToDate(
        date: Date | null | undefined,
        isStartDate: boolean
    ): Date | undefined {
        if (!date) {
            return undefined;
        }

        const { startTime, endTime } = this.state;
        let currentTime;
        if (isStartDate) {
            currentTime = startTime ? startTime : this.initialState.startTime;
        } else {
            currentTime = endTime ? endTime : this.initialState.endTime;
        }
        return this.addTimeToDate(date, currentTime);
    }

    addTimeToDate(date: Date, currentTime: string): Date {
        if (!date) {
            return date;
        }
        if (!currentTime) {
            return date;
        }

        const timeSplit = currentTime.split(':');
        if (timeSplit.length < 2) {
            return date;
        }

        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            parseInt(timeSplit[0], 10),
            parseInt(timeSplit[1], 10),
            0,
            0
        );
    }
    calculateButtonType = (
        fromDate: Date | null | undefined,
        toDate: Date | null | undefined
    ): ButtonType => {
        if (!fromDate || (!fromDate && !toDate)) {
            return ButtonType.All;
        }

        // Normalizes all the date to be with no time
        const today = this.normalizeDate(new Date());
        const todayTime = today.getTime();
        const fromDateTime = fromDate.getTime();
        const toDateTime = toDate ? toDate.getTime() : undefined;

        // Check if we are in the 'Today' case.
        if (fromDateTime === todayTime && toDateTime === todayTime) {
            return ButtonType.Today;
        }

        // Check if we are in the 'LastYear' case.
        const currentYear = today.getFullYear();
        const fromDatePastYearTime = new Date(currentYear, 0, 1, 0, 0, 0, 0) // Normalized date.
            .getTime();
        if (fromDateTime === fromDatePastYearTime && toDateTime === todayTime) {
            return ButtonType.LastYear;
        }

        // Check if we are in the 'Last7Days' case.
        const fromDatePast7Days = new Date(todayTime);
        fromDatePast7Days.setDate(today.getDate() - 7);
        const fromDatePast7DaysTime = fromDatePast7Days.getTime();
        if (
            fromDateTime === fromDatePast7DaysTime &&
            toDateTime === todayTime
        ) {
            return ButtonType.Last7Days;
        }

        // Check if we are in the 'Last30Days' case.
        const fromDatePast30Days = new Date(todayTime);
        fromDatePast30Days.setDate(today.getDate() - 30);
        const fromDatePast30DaysTime = fromDatePast30Days.getTime();
        if (
            fromDateTime === fromDatePast30DaysTime &&
            toDateTime === todayTime
        ) {
            return ButtonType.Last30Days;
        }

        return ButtonType.None;
    };

    invokeRangeChanged(
        from: Date | undefined,
        to: Date | undefined,
        buttonType: ButtonType
    ) {
        const { onDateRangeChange } = this.props;

        if (onDateRangeChange) {
            onDateRangeChange({
                from,
                to,
                buttonType,
            } as CCDatePickerDateRange);
        }
    }

    onDayClicked = (dateRaw: Date) => {
        // Normalizes the selected date by adding just the date but no time.
        const dateTime = this.normalizeDate(dateRaw);
        const todayTime = this.normalizeDate(new Date());

        if (dateTime.getTime() > todayTime.getTime()) {
            return;
        }

        const { fromDate, toDate } = this.state;

        let rangeModifier: RangeModifier;

        // If a range is already defined we clear the range so we can start a new one.
        if (!!fromDate && !!toDate) {
            // Double casting is done to overcome a type definition's bug in the component.
            rangeModifier = {
                from: undefined,
                to: undefined,
            } as unknown as RangeModifier;
        } else {
            rangeModifier = { from: fromDate, to: toDate } as RangeModifier;
        }

        const range = DayPicker.DateUtils.addDayToRange(
            dateTime,
            rangeModifier
        );
        const { from, to } = range;

        const fromWithTime = this.addStateTimeToDate(from, true);
        const toWithTime = this.addStateTimeToDate(to, false);
        this.setState({ fromDate: fromWithTime, toDate: toWithTime });
        this.invokeRangeChanged(
            fromWithTime,
            toWithTime ? toWithTime : this.addStateTimeToDate(from, false),
            this.calculateButtonType(from, to)
        );
    };

    onShortcutButtonClicked = (selectedButton: ButtonType) => () => {
        let newState: States = {} as States;

        // Normalizes today's date by adding just the date but no time.
        const todayTime = this.normalizeDate(new Date()).getTime();
        const today = new Date(todayTime);

        switch (selectedButton) {
            case ButtonType.LastYear: {
                const currentYear = today.getFullYear();
                const fromDate = this.addStateTimeToDate(
                    new Date(currentYear, 0, 1, 0, 0, 0, 0),
                    true
                );
                const toDate = this.addStateTimeToDate(today, false);
                newState = { fromDate, toDate } as States;
                break;
            }

            case ButtonType.Last30Days: {
                // Today's time is already normalized no need to normalize it again.
                const fromDateWithoutTime = new Date(todayTime);
                fromDateWithoutTime.setDate(today.getDate() - 30);
                const fromDate = this.addStateTimeToDate(
                    fromDateWithoutTime,
                    true
                );
                const toDate = this.addStateTimeToDate(today, false);
                newState = { fromDate, toDate } as States;
                break;
            }

            case ButtonType.Last7Days: {
                // Today's time is already normalized no need to normalize it again.
                const fromDateWithoutTime = new Date(todayTime);
                fromDateWithoutTime.setDate(today.getDate() - 7);
                const fromDate = this.addStateTimeToDate(
                    fromDateWithoutTime,
                    true
                );
                const toDate = this.addStateTimeToDate(today, false);
                newState = { fromDate, toDate } as States;
                break;
            }

            case ButtonType.Today: {
                const fromDate = this.addStateTimeToDate(today, true);
                const toDate = this.addStateTimeToDate(today, false);
                newState = { fromDate, toDate } as States;
                break;
            }

            default:
                newState = Object.assign({}, this.initialState) as States;
                break;
        }

        this.setState(newState);
        this.invokeRangeChanged(
            newState.fromDate,
            newState.toDate,
            selectedButton
        );
    };

    onTimeChanged = (isStartTime: boolean) => (time: string) => {
        const newState: States = {} as States;
        if (isStartTime) {
            newState.startTime = time;
        } else {
            newState.endTime = time;
        }
        this.setState(newState, () => {
            const { fromDate, toDate } = this.state;
            if (!fromDate || !toDate) {
                return;
            }

            const from = this.addStateTimeToDate(fromDate, true);
            const to = this.addStateTimeToDate(toDate, false);
            this.invokeRangeChanged(
                from,
                to,
                this.calculateButtonType(from, to)
            );
        });
    };
    onResetClicked = () => {
        const { startTime, endTime } = this.initialState;

        const { fromDate, toDate } = this.state;

        this.setState({ startTime, endTime });

        const from = fromDate
            ? this.addTimeToDate(fromDate, startTime)
            : undefined;
        const to = toDate ? this.addTimeToDate(toDate, endTime) : undefined;
        this.invokeRangeChanged(from, to, this.calculateButtonType(from, to));
    };

    public render() {
        const { classes, className, numberOfMonths } = this.props;
        const { fromDate, toDate, startTime, endTime } = this.state;
        const modifiers = {
            start: fromDate,
            end: toDate,
        };
        const buttonCommonProps = {
            classes: {
                disabled: classes.buttonDisabled,
            },
            className: classes.button,
            variant: 'outlined' as
                | 'text'
                | 'outlined'
                | 'contained'
                | undefined,
        };
        const selectedButton = this.calculateButtonType(fromDate, toDate);
        const rootClasses = `${classes.root}${
            className ? ` ${className}` : ''
        }`;

        return (
            <div className={rootClasses}>
                <div className={classes.container}>
                    <DayPicker
                        className={classes.dayPicker}
                        numberOfMonths={numberOfMonths}
                        selectedDays={[
                            fromDate,
                            { from: fromDate, to: toDate } as RangeModifier,
                        ]}
                        disabledDays={{ after: new Date() }}
                        modifiers={modifiers}
                        month={fromDate}
                        toMonth={new Date()}
                        onDayClick={this.onDayClicked}
                    />
                    <div className={classes.buttonContainer}>
                        <Button
                            {...buttonCommonProps}
                            disabled={selectedButton === ButtonType.All}
                            color="info"
                            onClick={this.onShortcutButtonClicked(
                                ButtonType.All
                            )}
                        >
                            All Dates
                        </Button>
                        <Button
                            {...buttonCommonProps}
                            disabled={selectedButton === ButtonType.LastYear}
                            color="info"
                            onClick={this.onShortcutButtonClicked(
                                ButtonType.LastYear
                            )}
                        >
                            Last Year
                        </Button>
                        <Button
                            {...buttonCommonProps}
                            disabled={selectedButton === ButtonType.Last30Days}
                            color="info"
                            onClick={this.onShortcutButtonClicked(
                                ButtonType.Last30Days
                            )}
                        >
                            Last 30 Days
                        </Button>
                        <Button
                            {...buttonCommonProps}
                            disabled={selectedButton === ButtonType.Last7Days}
                            color="info"
                            onClick={this.onShortcutButtonClicked(
                                ButtonType.Last7Days
                            )}
                        >
                            Last 7 Days
                        </Button>
                        <Button
                            {...buttonCommonProps}
                            disabled={selectedButton === ButtonType.Today}
                            color="info"
                            onClick={this.onShortcutButtonClicked(
                                ButtonType.Today
                            )}
                        >
                            Today
                        </Button>
                    </div>
                </div>
                <div
                    className={`${classes.container} ${classes.timePickerContainer}`}
                >
                    <CCTimePicker
                        className={classes.timePicker}
                        classes={{
                            textField: classes.timePickerTextField,
                            textFieldLabel: classes.timePickerTextFieldLabel,
                            input: classes.timePickerInput,
                        }}
                        label="Start Time"
                        time={startTime}
                        onChange={this.onTimeChanged(true)}
                    />
                    <CCTimePicker
                        className={classes.timePicker}
                        classes={{
                            textField: classes.timePickerTextField,
                            textFieldLabel: classes.timePickerTextFieldLabel,
                            input: classes.timePickerInput,
                        }}
                        label="End Time"
                        time={endTime}
                        onChange={this.onTimeChanged(false)}
                    />
                    <Button
                        className={classes.resetButton}
                        variant="outlined"
                        color="info"
                        onClick={this.onResetClicked}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(CCDatePicker);
export { MUIComponent as CCDatePicker };
export { ButtonType as CCDatePickerButtonType };
