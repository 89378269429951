import * as React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    FormControl,
    IconButton,
    InputBase,
    Paper
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@mui/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        flexDirection: 'row'
    },
    paper: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        padding: '0.2em',
    },
    input: {
        marginLeft: '0.8em',
        flex: 1,
    },
    searchIconButton: {
        padding: 0
    },
    searchIcon: {
        marginLeft: '0.3em'
    },
    buttonSearch: {
        marginLeft: '1.5em',
        paddingRight: '0.7em'
    },
    clearButton: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        padding: 0,
        margin: '0 0.2em',
        '&.hidden': {
            opacity: 0
        }
    },
    clearButtonIcon: {
        width: '0.6em',
        height: '0.6em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    disabled: boolean;
    initialSearchValue?: string;
    buttonText?: string;
    searchIconButtonDisabled: boolean;
    placeholder?: string;
    buttonColor: 'inherit' | 'primary' | 'error' | 'secondary' | 'success' | 'info' | 'warning' | undefined;
    onSearchClick?: (searchValue: string) => void;
}

interface States {
    searchValue: string;
}

class SearchInput extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
        disabled: false,
        searchIconButtonDisabled: false,
        buttonColor: 'primary'
    };

    state = {
        searchValue: this.props.initialSearchValue ?
            this.props.initialSearchValue : ''
    };
    
    isValueSubmitted = this.state.searchValue !== '';

    onSearchClicked= () => {
        const {onSearchClick} = this.props;
        const {searchValue} = this.state;
        this.isValueSubmitted = true;

        if (onSearchClick) {
            onSearchClick(searchValue.trim());
        }
    };

    onSearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target as HTMLInputElement;

        this.setState({
            searchValue: value
        });
    };

    onKeyPressed = (event: React.ChangeEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>) => {
        // If the user press enter we invoke the search callback
        // and deselect the component.
        if( event.key === 'Enter' ) {
            event.preventDefault();
            event.target.blur();
            this.onSearchClicked();
        }
    };

    onClearClicked = () => {
        this.setState({
            searchValue: ''
        });

        // If the value was submitted we notify the parent that the value was cleared.
        const {onSearchClick} = this.props;
        if (this.isValueSubmitted && !!onSearchClick) {
            onSearchClick('');
        }
    };

    public render() {
        const {
            className,
            classes,
            buttonText,
            disabled,
            searchIconButtonDisabled,
            placeholder,
            buttonColor
        } = this.props;
        const {
            searchValue
        } = this.state;
        const clearButtonClasses = `${classes.clearButton} ${classes.clearButtonIcon}${!searchValue ? ' hidden' : ''}`;
        
        return (
            <FormControl
                className={`${classes.root}${className ? ` ${className}` : ''}`}
                onKeyPress={this.onKeyPressed}
                disabled={disabled}
            >
                <Paper className={classes.paper}>
                    <InputBase
                        className={classes.input}
                        placeholder={placeholder}
                        value={searchValue}
                        onChange={this.onSearchChanged}
                    />
                    <IconButton
                        className={clearButtonClasses}
                        onClick={this.onClearClicked}
                    >
                        <ClearIcon className={classes.clearButtonIcon} />
                    </IconButton>
                    <IconButton
                        className={classes.searchIconButton}
                        disableRipple={true}
                        disabled={disabled || searchIconButtonDisabled || !!buttonText}
                        onClick={this.onSearchClicked}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
                { buttonText ?
                    <Button
                        className={classes.buttonSearch}
                        variant="contained"
                        disabled={disabled}
                        onClick={this.onSearchClicked}
                        color={buttonColor}
                    >
                        {buttonText}
                        <SearchIcon className={classes.searchIcon} />
                    </Button>
                    : ''
                }
            </FormControl>
        );
    }
}

const MUIComponent = withStyles(styles)(SearchInput);
export {MUIComponent as SearchInput};
